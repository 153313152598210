<template>
    <div>     
      <vs-prompt
      @cancel="Canceld"
      @accept="Accepted"
      @close="Closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.SAVE')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddOfferMemberDlg"
      :is-valid="formValid">
       <div>
        <div v-show="!text_only">
          <vs-chip class="mr-3" v-show="pricesLastYear" color="primary"><vs-avatar icon="error" color="black"/>{{$t('ACCOUNTING.ACC_MEMBER.PRICE_LAST_YEAR')}}</vs-chip>
          <vs-divider>{{$t('OFFERS.MEMBER.EDIT.DIVIDER.PROD_MOUNT')}}</vs-divider>
          <div class="default-input clearfix align-items-center mb-3">
            <div class="float-left">
              <ProductPicker :label="productLabel" :isLabelOkay="true" :pricesLastYear="pricesLastYear" :country_fk="country_fk" :currency_fk="currency_fk" v-model="product_fk" :withMotifpickerOnly="false"></ProductPicker>
            </div>
            <div class="float-left" v-if="selectedRooms.length > 0">
              <vs-select   :label="$t('OFFERS.MEMBER.EDIT.ROOM')" v-model="room_fk">
                <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in selectedRooms" />
              </vs-select> 
            </div>
          </div>
          <template v-for="(grp, index) in product_variants">
            <div :key="'vargrp'+index">
              <vs-divider>{{grp.label}}</vs-divider>
              <div class="d-flex mb-3 mt-3 clearfix"> 
                <template v-for="(variant, idx_var) in grp.variants">
                    <div :key="'var'+idx_var">
                    <vs-tooltip
                       color="primary"
                       :active="variant.netto_txt.length > 0"
                        :text="variant.netto_txt">
                      
                    <vs-radio  v-model="grp.selected_variant_fk" :vs-name="'variant_rbtn_'+index" :vs-value="variant.id" class="mr-3 float-left"><span >{{variant.label}}</span>
                      <div v-if="variant.extraoption == RALPICKER">
                        <RalColorPicker :disabled="ralSelectDisabled" v-model="ralColor_fk" :showColorText="true" :label="$t('OFFERS.MEMBER.EDIT.LABEL.STD_RAL')"></RalColorPicker>
                      </div>
                      <div v-if="variant.extraoption == MOTIFPICKER">
                        <MotifPicker :disabled="motifSelectDisabled" class ="mt-2" v-model="motif_data" :product_fk="product_fk" :label="$t('OFFERS.MEMBER.EDIT.LABEL.MOTIF')" 
                        :currencyKurzel="currencyKurzel" :countryLanguageFk="countryLanguageFk" v-on:Accepted="CalcPrices"></MotifPicker>
                      </div>
                    </vs-radio>
                    </vs-tooltip>
                    </div>
                </template>
              </div>  
            </div>
          </template>
          <vs-divider>{{$t('OFFERS.MEMBER.EDIT.DIVIDER.PRICES')}}</vs-divider>
          <div class="default-input clearfix align-items-center mb-3">
            <span>{{$t('OFFERS.MEMBER.EDIT.LABEL.NET') + ': '}}</span><span class="font-size-120p">{{netto_txt}}</span>
            <span class="ml-3">{{$t('OFFERS.MEMBER.EDIT.LABEL.BRUT') + ': '}}</span><span class="font-size-120p">{{brutto_txt}}</span>
          </div>
          <vs-divider>{{$t('OFFERS.MEMBER.EDIT.DIVIDER.PRICE_SUM')}}</vs-divider>
          <div class="default-input clearfix align-items-center mb-3">
            <vs-input-number  v-model="amount" size="small" min="1" class="inputx float-left" :label="$t('OFFERS.MEMBER.EDIT.LABEL.AMOUNT')"></vs-input-number>
          </div>
          <div class="default-input clearfix align-items-center mb-3">
            <span>{{$t('OFFERS.MEMBER.EDIT.LABEL.NETTO_SUM') + ': '}}</span><span class="font-size-120p">{{netto_sum_txt}}</span>
            <span class="ml-3">{{$t('OFFERS.MEMBER.EDIT.LABEL.BRUTTO_SUM') + ': '}}</span><span class="font-size-120p">{{brutto_sum_txt}}</span>
          </div>
          <div v-if="extra_products.length > 0" class="default-input clearfix align-items-center mb-3">
            <vs-list>
              <vs-list-header :title="$t('OFFERS.MEMBER.EDIT.LABEL.EXTRA_PRODUCTS')"></vs-list-header>
              <template v-for="(extraprod, idx) in extra_products">
                <vs-list-item :key="'extraprod_'+idx" title="" :subtitle="amount + 'x '+extraprod.bez"><div class="d-flex"><vs-checkbox v-model="extraprod.selected"></vs-checkbox><vs-chip >{{extraprod.netto_txt}} | {{extraprod.brutto_txt}}</vs-chip></div></vs-list-item>
              </template>
            </vs-list>
          </div>
          <vs-divider>{{$t('OFFERS.MEMBER.EDIT.DIVIDER.TEXTS')}}</vs-divider>
          <div class="default-input clearfix align-items-center mb-2">
            <vs-input :label="$t('OFFERS.MEMBER.EDIT.LABEL.OFFER_TEXT')" class="mr-2 inputx float-left"  v-model="text" />
          </div>
          <div class="default-input clearfix align-items-center mb-3">
            <vs-checkbox v-model="text_only"  class="inputx float-left" >{{$t('OFFERS.MEMBER.EDIT.SHOW_TEXT_ONLY')}}</vs-checkbox>
          </div>
        </div>
       <div v-show="text_only">
          <vs-textarea height="300px" counter="500" :label="$t('OFFERS.MEMBER.EDIT.LABEL.OFFER_TEXT')" :counter-danger.sync="text_fail" v-model="text" />
          <div class="default-input clearfix align-items-center mb-3">
            <vs-checkbox v-model="text_only"  class="inputx float-left" >{{$t('OFFERS.MEMBER.EDIT.SHOW_TEXT_ONLY')}}</vs-checkbox>
          </div>
       </div>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';
import { productVariantExtraOptions } from '../../../helper/enumHelper';
import { orientation } from '../../../helper/enumHelper';
import RalColorPicker from '../RalColorPicker';
import ProductPicker from '../ProductPicker.vue';
import MotifPicker from '../MotifPicker.vue';

export default {
  name: "AddOfferMemberDlg",
  props:['InitData'],
  components:{
    RalColorPicker,
    ProductPicker,
    MotifPicker
    },
  data: function (){
     return {
      dlgActive: false,
      popupTitle: this.$t('OFFERS.MEMBER.ADD.HEADER'),

      id:0,
      prices:[],
      product_fk:0,
      netto:0,
      aufpreis:0,
      netto_txt:"",
      brutto_txt:"",
      amount:1,
      surface_txt:"",
      act_pricelistmember:null,
      ralColor_fk:-1,
      ralColor_isStd:true,
      brutto:0,
      brutto_sum:0,
      netto_sum:0,
      brutto_sum_txt:"",
      netto_sum_txt:"",
      offer_fk:0,
      article_nr:"",
      text_only:false,
      text:"",
      orderNr:0,
      text_fail:false,
      pricesLastYear:false,
      country_fk:0,
      currency_fk:0,
      product_variants:[],
      selected_variant_fk:0,
      selected_variants:[],
      variantsFromInitDlg:[],
      RALPICKER:productVariantExtraOptions.RALPICKER,
      MOTIFPICKER:productVariantExtraOptions.MOTIFPICKER,
      motif_data:{motif_fk:0,motif_orientation:orientation.HORIZONTAL,motif_netto:0},
      extra_products:[],
      rooms:[],
      room_fk:0
     }
  }, 
  mounted () {
    this.$store.dispatch('productvariant/getProductvariants'); 
    this.$store.dispatch('productvariant/getProductvariantGroups'); 
    
    this.ClearForm();
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      ShowDlg(offer_fk, pr,text_only,lastyear,country_fk,currency_fk){
        this.ClearForm();
        if(this.IsNotNull(pr) && this.IsNotNull(offer_fk))
        {
          this.offer_fk = offer_fk;
          this.prices= pr;
          this.text_only = text_only;
          this.pricesLastYear = lastyear;
          this.country_fk = country_fk;
          this.currency_fk = currency_fk;

          if(text_only == false)
          {
            // Erstes Produkt in der Liste vorschlagen
            if(this.products.length > 1 )
            {
              this.product_fk = this.products[1].value;
            }
          }
          this.dlgActive = true;
        }
      },
      Closed(){
        this.ClearForm();
      },
      InitDlgData(prices,data,lastyear,country_fk,currency_fk){
        
        if(this.IsNotNull(prices) && this.IsNotNull(data))
        {
            this.popupTitle = this.$t('OFFERS.MEMBER.EDIT.HEADER'),
            this.prices = prices;

            this.id = data.id;
            this.offer_fk = data.offer_fk;
            this.product_fk = data.product_fk;
            this.product_bez = data.product_bez;
            this.ralColor_fk = data.ral_fk;
            //netto wird ermittelt
            //mwst_fk nicht relevant
            //brutto wird berechnet
            this.text = data.text;
            this.text_only = data.text_only;
            this.surface_txt = data.surface_txt;
            this.amount = data.amount;
            this.orderNr = data.orderNr;
            this.pricesLastYear = lastyear;
            this.country_fk = country_fk;
            this.currency_fk = currency_fk;
            this.selected_variants = [];
            this.variantsFromInitDlg = data.productVariants;
            this.motif_data.motif_fk = data.motif_fk;
            this.motif_data.motif_orientation = data.motif_orientation;
            this.motif_data.motif_netto = data.motif_netto;
            this.room_fk = data.room_fk;
            this.dlgActive=true;
            
        }
      },
      Accepted(){

          var data = {
            id: this.id,
            offer_fk: this.offer_fk,
            product_fk: this.product_fk,
            product_bez: this.product_bez,
            article_nr: this.article_nr,
            ral_fk:this.ralColor_fk,
            netto:this.netto + this.aufpreis,
            mwst_fk:-1,
            brutto:this.brutto,
            text:this.text,
            text_only:this.text_only,
            surface_txt:this.surface_txt,
            amount:Number(this.amount),
            sum_netto:this.netto_sum,
            sum_brutto:this.brutto_sum,
            mwst_val:0,
            currency_kuerzel:this.currencyKurzel,
            language_fk:this.countryLanguageFk,
            orderNr:this.orderNr,
            productVariants:this.selected_variants,
            motif_fk:this.motif_data.motif_fk,
            motif_orientation:this.motif_data.motif_orientation,
            motif_netto:this.motif_data.motif_netto,
            extra_products:this.extra_products,
            room_fk:this.room_fk
          };

          if(this.IsNotNull(this.act_pricelistmember))
          {
            data.mwst_fk = this.act_pricelistmember.mwst_fk;
            data.mwst_val = this.GetMwstVal(this.act_pricelistmember.mwst_fk);
          }

          var name = this.product_bez;

          if(data.text_only == true)
          {
            name = this.LimitTextTo(data.text,20);
          }

          this.$store.dispatch('accounting/saveOfferMember', data)
          .then(response => {
            if(response.success == true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('OFFERS.OFFERMEMBER.SUCCESS.SAVE').replace("*1*",name)});  
            }
            else
            {
              var text = this.$t('OFFERS.OFFERMEMBER.WARNING.SAVE').replace("*1*",name);
              text = text.replace("*2*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
            this.$emit('Accepted');
          }, 
          error =>{
              var text = this.$t('OFFERS.OFFERMEMBER.ERROR.SAVE').replace("*1*",name);
              text = text.replace("*2*",error);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
              this.$emit('Accepted');
          });
          
          this.ClearForm();
      },
      Canceld(){
        this.ClearForm();
      },
      ClearForm(){
       
        this.popupTitle = this.$t('OFFERS.MEMBER.ADD.HEADER');

        this.id = 0;
        this.offer_fk = 0;
        this.product_fk = -1;
        this.product_bez = "";
        this.ralColor_fk = -1
        this.netto = 0;
        this.brutto = 0;
        this.text = "";
        this.text_only = false;
        this.surface_txt = "";
        this.amount = 1;
        this.netto_sum = 0;
        this.brutto_sum = 0;
        this.orderNr = 0;
        this.pricesLastYear = false;
        this.country_fk = 0;
        this.currency_fk = 0;
        this.selected_variants = [];
        this.variantsFromInitDlg = [];
        this.motif_data.motif_fk = 0;
        this.motif_data.motif_orientation = orientation.HORIZONTAL;
        this.motif_data.motif_netto = 0;
        this.extra_products = [];
        this.room_fk = 0;

      },
      GetPricelistmember(product_fk){
        var retVal = null;
        
        if(product_fk > 0)
        {
          for(var i = 0; i < this.prices.length; i++)
          {
            for(var j = 0; j < this.prices[i].member.length; j++)
            {
              var mem = this.prices[i].member[j];
              
                if(mem.product_fk == product_fk)
                {
                  retVal = mem;
                  break;
                }
            }
          }
        }
        return retVal;
      },
      GetMwstVal(mwst_fk)
      {
        var retVal = 0;
        if(this.prices != null && !this.IsEmpty(this.prices))
        {
          for(var i = 0; i < this.prices.length; i++)
          {
            for(var j = 0; j < this.prices[i].mwStList.length; j++)
            {
              if(this.prices[i].mwStList[j].id == mwst_fk)
              {
                retVal = this.prices[i].mwStList[j].value;
                break;
              }
            }
          }
        }
        return retVal;
      },
      GetProductFromPricelist(prod_fk)
      {
          var retVal = null;

          var pricelist = this.GetPriceListForProduct(prod_fk);

          if(this.IsNotNull(pricelist))
          {
            for(var i = 0; i < pricelist.products.length; i++)
            {
              if(pricelist.products[i].id == prod_fk)
              {
                retVal = pricelist.products[i];
                break;
              }
            }
          }
          return retVal;
      },
      GetPriceListForProduct(prod_fk)
      {
        var retVal = null;
          if(this.prices != null && !this.IsEmpty(this.prices))
          {
            for(var pr_idx = 0; pr_idx < this.prices.length; pr_idx++)
            {
              for(var i = 0; i < this.prices[pr_idx].products.length; i++)
              {
                if(this.prices[pr_idx].products[i].id == prod_fk)
                {
                  retVal = this.prices[pr_idx];
                  break;
                }
              }
            }
          }
          return retVal;
      },
      GetProductBez(prod_fk){
          var retVal = "";
          var prod = this.GetProductFromPricelist(prod_fk);
          if(this.IsNotNull(prod))
          {
            retVal = prod.bez;
          }
          return retVal;
      },
      GetProductArticleNr(prod_fk){
          var retVal = "";
          var prod = this.GetProductFromPricelist(prod_fk);
          if(this.IsNotNull(prod))
          {
            retVal = prod.article_nr;
          }
          return retVal;
      },
      GetVariantLabelShort(id){

        var ret = "";

        var variant = this.GetProductVariant(id);

        if(this.IsNotNull(variant))
        {
           ret = variant.label_short;
        }
        return ret;
      },
      GetVariantExtraoption(id){

        var ret = "";

        var variant = this.GetProductVariant(id);
        if(this.IsNotNull(variant))
        {
           ret = variant.extraoption;
        }
        return ret;
      },
      IsVariantMemberOfVariantGrp(grps,grp_id, variant_id){
        var ret = false;
        for(var i = 0; i < grps.length; i++)
        {
          if(grps[i].id == grp_id)
          {
            for(var j=0; j < grps[i].member.length; j++)
            {
              if(grps[i].member[j].product_variant_fk == variant_id)
              {
                ret = true;
                break;
              }
            }
            break;
          }
        }
        return ret;
      },
      LoadProductVariants(product_fk){
        // Erstellt laut Priceliste und Produkt alle verfügbaren Radiobuttons (Gruppen und Einträge)
        var variantGrps = [];
        var prices = this.GetPriceListForProduct(product_fk);
        if(this.IsNotNull(prices))
        {
          var member = this.GetPricelistmember(product_fk);

          if(this.IsNotNull(member))
          {
            
            prices.productvariantgroups.forEach(grp => {
              
              var grpvariantmember = [];
              member.variantMember.forEach(variant => {
                if(variant.active == true)
                {
                  if(this.IsVariantMemberOfVariantGrp(prices.productvariantgroups, grp.id, variant.productvariant_fk))
                  {
                    var text = this.GetVariantLabelShort(variant.productvariant_fk);
                    var extraoption = this.GetVariantExtraoption(variant.productvariant_fk);
                    
                    // Den Netto Aufpreis nur einrechnen und anzeigen wenn kein Zusatzprodukt hinzugefügt werden soll
                    var var_netto = variant.netto;
                    var extra_prod = null;
                    if(variant.product_fk > 0)
                    {
                      // Zusatzprodukt für VAriante vorhanden -> preise und bez extra merken
                      var_netto = 0;
                      var extra_netto = variant.netto == 0 ? variant.product_netto : variant.netto;
                      var product_brutto = this.CalcBrutto(this.GetMwstVal(this.act_pricelistmember.mwst_fk), extra_netto);
                      var product_netto_txt = this.DecimalToString(extra_netto,this.currencyKurzel,this.GetLocalFormatOfLanguage(this.countryLanguageFk));
                      var product_brutto_txt = this.DecimalToString(product_brutto,this.currencyKurzel,this.GetLocalFormatOfLanguage(this.countryLanguageFk));
                      
                      extra_prod = {id:variant.id, bez:variant.product_bez, product_fk:variant.product_fk, netto:extra_netto, brutto:product_brutto, netto_txt:product_netto_txt, brutto_txt:product_brutto_txt,selected:true};
                    }
                    
                    var netto_txt = "";
                    if(variant.netto != 0 )
                    {
                      netto_txt = '+ '+this.DecimalToString(variant.netto,this.currencyKurzel,this.GetLocalFormatOfLanguage(this.countryLanguageFk));
                    }
                    else if(variant.netto == 0 && variant.product_fk > 0)
                    {
                      netto_txt = '+ '+this.DecimalToString(variant.product_netto,this.currencyKurzel,this.GetLocalFormatOfLanguage(this.countryLanguageFk));
                    }

                    var newVariant =
                    {
                      id: variant.id,
                      productvariant_fk: variant.productvariant_fk,
                      netto:var_netto,
                      label:text,
                      isDefault:variant.isDefault,
                      extraoption:extraoption,
                      extra_prod:extra_prod,
                      netto_txt:netto_txt
                    };
                    
                    grpvariantmember.push(newVariant);
                  }
                }
              });

              var grpmem = {id:grp.id,bez:grp.bez,label:grp.label,order:grp.order,variants:grpvariantmember,selected_variant_fk:0};
              if(grpmem.variants.length > 0)
              {
                variantGrps.push(grpmem);
              }
            });
          }
        }
        return variantGrps;
      },
      GetSelectedAufpreis(){
        var aufpreis = 0;
        if(this.IsNotNull(this.selected_variants) && !this.IsEmpty(this.selected_variants))
        {
          this.selected_variants.forEach(variant => {
            aufpreis += variant.netto;
          });
        }
        
        if(this.IsNotNull(this.motif_data) && !this.IsEmpty(this.motif_data))
        {
          aufpreis += this.motif_data.motif_netto;
        }
        
        return aufpreis;
      },
      GenerateSelectedVariants(){
        // Ermittelt welche Radiobuttons aktiv sind und erstellt laut Auswahl die Daten für den Angebots-Eintrag in der DB
        this.selected_variants = [];
        this.extra_products = [];
        if(this.IsNotNull(this.product_variants) && !this.IsEmpty(this.product_variants))
        {
          this.product_variants.forEach(gruppe => {
            gruppe.variants.forEach(variant => {
              if(gruppe.selected_variant_fk == variant.id)
              {
                this.selected_variants.push(
                  {
                    id:0,
                    offermember_fk:this.id,
                    product_variant_fk:variant.productvariant_fk,
                    product_variant_group_fk:gruppe.id,
                    netto:variant.netto,
                    extraoption:variant.extraoption
                    });
                // Wenn extra produkte vorhanden -> diese ins array übernehmen damit die Liste angezeigt werden kann
                if(variant.extra_prod != null)
                {
                  this.extra_products.push(variant.extra_prod);
                }
                
              }
            });
          });
        }
      },
      SelectProductVariants(variants_of_member){
        // Setzt die Radiobuttons auf die Varianten laut gespeicherte Werte des Angebot-Eintrags
        
        if(this.IsNotNull(this.product_variants) && !this.IsEmpty(this.product_variants))
        {
          variants_of_member.forEach(mem => {
            this.product_variants.forEach(gruppe => {
              for(var i = 0; i < gruppe.variants.length; i++)
              {
                if(gruppe.variants[i].productvariant_fk == mem.product_variant_fk)
                {
                  gruppe.selected_variant_fk = gruppe.variants[i].id;
                  break;
                }
              }
            });
          });

          this.SetProductVariantsToDefault();
        }
      },
      SetProductVariantsToDefault(){
        if(this.IsNotNull(this.product_variants) && !this.IsEmpty(this.product_variants))
        {
          this.product_variants.forEach(grp => {
            if(grp.selected_variant_fk <= 0)
            {
              // für diese Gruppe ist kein Wert gesetzt -> auf default setzen
              grp.selected_variant_fk = this.GetDefaultValueOfGroup(grp);
            }
          });
        }
      },
      GetDefaultValueOfGroup(gruppe,){
        var retVal = 0;
        if(this.IsNotNull(this.product_variants) && !this.IsEmpty(this.product_variants))
        {
          this.product_variants.forEach(grp => {
            if(grp.id == gruppe.id)
            {
              grp.variants.forEach(variant => {
                  if(variant.isDefault == true)
                  {
                    retVal = variant.id;
                  }
              });
            }
          });
        }
        return retVal;
      },
      CalcPrices()
      {
          this.netto = 0;
          this.aufpreis=0;
          this.brutto = 0;
          this.netto_txt = "";
          this.brutto_txt = "";

          this.netto_sum = 0;
          this.brutto_sum = 0;
          this.netto_sum_txt = "";
          this.brutto_sum_txt = "";

        if(this.IsNotNull(this.act_pricelistmember))
        {          
          if(this.act_pricelistmember != null)
          {
            this.netto = this.act_pricelistmember.netto;

            this.aufpreis = this.GetSelectedAufpreis();

            this.brutto = this.CalcBrutto(this.GetMwstVal(this.act_pricelistmember.mwst_fk), this.netto + this.aufpreis);
            this.netto_txt = this.DecimalToString(this.netto + this.aufpreis,this.currencyKurzel,this.GetLocalFormatOfLanguage(this.countryLanguageFk));
            this.brutto_txt = this.DecimalToString(this.brutto,this.currencyKurzel,this.GetLocalFormatOfLanguage(this.countryLanguageFk));

            this.netto_sum = (this.netto + this.aufpreis) * Number(this.amount);
            this.brutto_sum = this.CalcBrutto(this.GetMwstVal(this.act_pricelistmember.mwst_fk), this.netto_sum);
            this.netto_sum_txt = this.DecimalToString(this.netto_sum,this.currencyKurzel,this.GetLocalFormatOfLanguage(this.countryLanguageFk));
            this.brutto_sum_txt = this.DecimalToString(this.brutto_sum,this.currencyKurzel,this.GetLocalFormatOfLanguage(this.countryLanguageFk));
          }
        }
      }
  },
  computed:{

    formValid(){
        var valid = this.bez_ok && this.schema_ok && this.company_fk > 0;
        return valid;
    },
    selectedRooms(){
       var data = [];
        if(this.$store.state.debitorobject.rooms.data != null)
        {
          data = this.$store.state.debitorobject.rooms.data;
        }
      return data;
    },
    currencyKurzel(){
      var ret = "";
      if(this.prices != null && !this.IsEmpty(this.prices))
      {
        if(this.prices.length > 0)
        {
          ret = this.prices[0].currency.kuerzel;
        }
      }
      return ret;
    },
    countryLanguageFk(){
      var ret = "";
      if(this.prices != null && !this.IsEmpty(this.prices))
      {
        if(this.prices.length > 0)
        {
          ret = this.prices[0].country.language_fk;
        }
      }
      return ret;
    },
    products(){
      var retVal = [];
      retVal.push({"text": "- - - - -", "value":-1});
      if(this.prices != null && !this.IsEmpty(this.prices))
      {
        this.prices.forEach(price => {
            price.products.forEach(product => {
            var val = {"text": product.bez, "value":product.id};

            // Nur Produkte die einen Eintrag in der Preisliste haben
            var mem = this.GetPricelistmember(product.id);
            if(mem != null)
            {
              retVal.push(val);
            }
          });
        });
      }

      return retVal;
    },
    selectedVariantFks(){
      var retVal = [];
      this.product_variants.forEach(gruppe => {
            retVal.push(gruppe.selected_variant_fk);
          });
      return retVal;
    },
    productLabel(){
      var retVal = this.$t('OFFERS.MEMBER.EDIT.LABEL.PRODUCT');

      if(this.article_nr.length > 0)
      {
        retVal += ' (' + this.article_nr + ')';
      }

      return retVal;
    },
    ralSelectDisabled(){
      var isDisabled = true;
      
      if(!this.IsEmpty(this.selected_variants))
      {
        this.selected_variants.forEach(variant => {
          if(variant.extraoption == productVariantExtraOptions.RALPICKER)
          {
            isDisabled = false;
          }
        });
      }
      return isDisabled;
    },
    motifSelectDisabled(){
      var isDisabled = true;
      
      if(!this.IsEmpty(this.selected_variants))
      {
        this.selected_variants.forEach(variant => {
          if(variant.extraoption == productVariantExtraOptions.MOTIFPICKER)
          {
            isDisabled = false;
          }
        });
      }
      return isDisabled;
    },
    prodvariantsForSelect(){
      return this.GetListOfProductVariantsForSelect();
    }
  },
  watch:{
    product_fk(value){
      var pricelistmember = this.GetPricelistmember(value);
      if(pricelistmember != null)
      {
          this.act_pricelistmember = pricelistmember;
          this.product_variants = this.LoadProductVariants(value);

          this.SelectProductVariants(this.variantsFromInitDlg);

          this.CalcPrices();

          this.product_bez = this.GetProductBez(value);
          this.article_nr = this.GetProductArticleNr(value);
      }
      else
      {
        // Nichts gefunden, beträge leer setzen
        this.netto = 0;
        this.brutto = 0;
        this.amount = 1;
        this.act_pricelistmember = null;
        this.CalcPrices();
        this.article_nr = '';
      }
      
    },
    selectedVariantFks(){
      this.GenerateSelectedVariants();
      this.CalcPrices();
    },
    amount(){
      this.CalcPrices();
    },
    text_only(value){
      if(value == true)
      {
        this.product_fk = -1;
      }
    },
    motifSelectDisabled(value){
      if(value == true)
      {
        this.motif_data.motif_fk = 0;
        this.motif_data.motif_netto = 0;
        this.CalcPrices();
      }
    }
  }
};
</script>
<style>
div#promptAddOfferMemberDlg > div.vs-dialog{
  max-width: 900px !important;
}
</style>