<template>
  <div >
    <div class=" parentdiv-textarea ">
        <vs-textarea :label="activeLabel" v-model="text" class="textAreaAccordeon" />
        <vs-button :disabled="!buttonsActive" class="textAreaAccordeon_AddBtn" icon="add" size="small" @click="popupAddActive = true"></vs-button>
        <vs-button  class="textAreaAccordeon_OpenBtn" icon="open_in_new" size="small" @click="popupActive = true"></vs-button>
        <vs-button :disabled="!buttonsActive" class="textAreaAccordeon_ClearBtn" icon="delete" size="small" color="gray" @click="text = ''"></vs-button>
    </div>
        <vs-popup :title="$t('COM.LAYOUTS')" :active.sync="popupActive" style="z-index:30008">
            <vs-collapse v-if="userdefinedOptions.length > 0" open-hover type="border" >
                <template v-for="(item, idx) in userdefinedOptions">
                    <vs-collapse-item :key="'txt'+idx" icon-arrow="">
                        <div slot="header" class="item_header">
                            {{item.text}}
                            <vs-button icon="arrow_right" class="btn_takethis" @click="TakeText(item.text_long)"></vs-button>
                            <vs-button icon="delete" class="btn_delete" color="gray" @click="DeleteEntry(item.id)" ></vs-button>
                        </div>
                        {{item.text_long}}
                    </vs-collapse-item>
                </template>
            </vs-collapse>
            <div  v-if="userdefinedOptions.length == 0"><h4>{{$t('COM.NO_LAYOUTS')}}</h4></div>
        </vs-popup>
        <vs-popup :title="$t('COM.SAVE')" :active.sync="popupAddActive" style="z-index:30009">
            <div class="default-input clearfix align-items-center mb-3">
                <div class="float-left" >
                    <vs-input :label="$t('COM.TITLE')" v-model="txt_header"></vs-input>
                </div>
            </div>
            <div class="default-input clearfix align-items-center mb-3">
                <vs-textarea :label="activeLabel" v-model="text" />
            </div>
            
            <vs-button :disabled="txt_header.length == 0" @click="AddEntry()">{{$t('COM.SAVE')}}</vs-button>
        </vs-popup>        
  </div>
</template>
<script>
import helperMethods from '../../helper/staticFuncHelper';
import codeHelper from '../../helper/staticCodeHelper';
import enumHelper from '../../helper/enumHelper';

export default {
  name: "TextAreaAccordeon",
  props:['value', 'type', 'label'],
  components:{},
   data: function (){
     return {
      text:this.value,
      popupActive:false,
      popupAddActive:false,
      options:[],
      userdefinedOptions:[],
      txt_header:""
     }
  },
  mounted(){
    this.$store.dispatch('userdefinedOptions/getUserdefinedOptions', this.type);    
  },
  methods:{
    ...helperMethods,
    ...enumHelper,
    ...codeHelper,
    TakeText(vorlage){
        this.text = vorlage;
        this.popupActive = false;
    },
    AddEntry(){

      var data = {type:this.type,text:this.txt_header, text_long:this.text};
      var name = this.text;

      this.$store.dispatch('userdefinedOptions/insertUserdefinedOption', data)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('COM.ADD.SUCCESS').replace("*1*",name)});  
                this.$store.dispatch('userdefinedOptions/getUserdefinedOptions', this.type);
                this.popupAddActive = false;
                this.txt_header = "";
            }
            else
            {
              var text = this.$t('COM.ADD.WARNING.SAVE').replace("*1*",name);
              text = text.replace("*2*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
          }, 
          error =>{
              var text = this.$t('COM.ADD.ERROR.SAVE').replace("*1*",name);
              text = text.replace("*2*",error);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
          });
    },
    DeleteEntry(id){

      var name = this.text;

      this.$store.dispatch('userdefinedOptions/deleteUserdefinedOption', id)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('COM.ADD.SUCCESS').replace("*1*",name)});  
                this.$store.dispatch('userdefinedOptions/getUserdefinedOptions', this.type);
            }
            else
            {
              var text = this.$t('COM.ADD.WARNING.DELETE').replace("*1*",name);
              text = text.replace("*2*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
          }, 
          error =>{
              var text = this.$t('COM.ADD.ERROR.DELETE').replace("*1*",name);
              text = text.replace("*2*",error);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
          });
    }    
  },
  computed:{
    activeLabel(){
      var retval = '';
      if(this.IsNotNull(this.label))
      {
        retval = this.label;
      }
      return retval;
    },
    buttonsActive(){
      var retval = true;
      if(this.IsNotNull(this.txt))
      {
        retval = this.txt.length > 0;
      }
      return retval;
    },    
    userdefinedOptionsLoaded(){
      var retVal = [];
      if(this.IsNotNull(this.$store.state.userdefinedOptions.options.data))
      {
        var data = this.$store.state.userdefinedOptions.options.data;
        for(var i = 0; i < data.length; i++)
        {
          if(data[i].type == this.type)
          {
            data[i].options.forEach(element => {
              retVal.push(element);
            });
            break;
          }
        }
      }
      return retVal;
    }
  },
  watch:{
    userdefinedOptionsLoaded(value){
      this.userdefinedOptions = value;
    },
    text(value)
    {
      this.$emit('input', value);
    }
  }
};
</script>
<style>
.parentdiv-textarea {
    position: relative;
}
.textAreaAccordeon_AddBtn{
    position: absolute !important;
    bottom:-10px;
    right:10px;
}
.textAreaAccordeon_OpenBtn{
    position: absolute !important;
    bottom:-10px;
    right:50px;
}
.textAreaAccordeon_ClearBtn{
    position: absolute !important;
    bottom:-10px;
    right:90px;
}
.item_header{
    position: relative;
}
.btn_takethis{
    position: absolute !important;
    right:10px;
    top:0;
    height:10px !important;
    width:10px !important;
}
.btn_delete{
    position: absolute !important;
    right:35px;
    top:0;
    height:10px !important;
    width:10px !important;
}
</style>
